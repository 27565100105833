<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
</mat-dialog-actions>

<div class="action-buttons">
  <button mat-icon-button (click)="downloadFile()" aria-label="Download">
    <mat-icon>download</mat-icon>
  </button>
  <button mat-icon-button (click)="zoomIn()" aria-label="Zoom In">
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button mat-icon-button (click)="zoomOut()" aria-label="Zoom Out">
    <mat-icon>zoom_out</mat-icon>
  </button>
  <div *ngIf="fileType === 'document'" class="pdf-navigation">
    <button
      mat-icon-button
      (click)="previousPage()"
      aria-label="Previous Page"
      [disabled]="isPreviousPageDisabled()"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <span>{{ currentPage }} / {{ totalPages }}</span>
    <button
      mat-icon-button
      (click)="nextPage()"
      aria-label="Next Page"
      [disabled]="isNextPageDisabled()"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content>
  <div *ngIf="fileType === 'image'">
    <img [src]="url" alt="{{ title }}" class="image-viewer" />
  </div>
  <div *ngIf="fileType === 'document'" id="pdf-container"></div>
  <div *ngIf="fileType === 'tiff'" id="tiff-container"></div>

  <div class="info">
    <div class="title">{{ title }}</div>
    <div class="file-type">
      {{ fileType }} <span *ngIf="fileSize && fileSize > 0"> · {{ fileSize }} KB</span>
    </div>
  </div>

  <div class="navigation-buttons" *ngIf="urls.length > 1">
    <button
      mat-icon-button
      (click)="previousUrl()"
      class="nav-button left"
      aria-label="Previous"
      [disabled]="isPreviousDisabled()"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="nextUrl()"
      class="nav-button right"
      aria-label="Next"
      [disabled]="isNextDisabled()"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</mat-dialog-content>
