import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationService } from '@app/services/organization.service';
import { DriverProfile } from '@app/models/driverProfile';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  private baseUrl = environment.dataApiUrl;
  private driversUrl = 'drivers';
  private driverScoringUrl = 'score';
  private filterListUrl = 'drivers/filter/list';
  private accidentUrl = 'accident';
  private injuryUrl = 'injury';
  private hosUrl = 'hos/violation';

  private selectedOrganization!: any;

  constructor(
    private http: HttpClient,
    private organizationService: OrganizationService
  ) {
    this.loadSelectedOrganization();
    this.subscribeToOrganizationChange();
  }

  loadSelectedOrganization(): void {
    let retries = 20;

    const tryLoadOrganization = () => {
      const storedOrganization = localStorage.getItem('selectedOrganization');
      if (storedOrganization) {
        this.selectedOrganization = JSON.parse(storedOrganization);
      } else if (retries > 0) {
        retries--;
        setTimeout(tryLoadOrganization, 1000);
      } else {
        console.error('Failed to load organization after maximum retries');
      }
    };

    tryLoadOrganization();
  }

  subscribeToOrganizationChange(): void {
    this.organizationService.organization$.subscribe((organization) => {
      if (organization) {
        this.selectedOrganization = organization;
      }
    });
  }

  getDriverList(params: any): Observable<any> {
    const paramMappings: any = {
      searchField: 'searchField',
      pageSize: 'pageSize',
      page: 'pageNumber',
      terminal: 'terminal',
      driverTypes: 'driverType',
      workStatuses: 'driverCategory',
      status: 'status',
      sortField: 'sortField',
      sortValue: 'sortValue',
      driverScoreMin: 'driverScoreMin',
      driverScoreMax: 'driverScoreMax',
      userId: 'userId',
      bookMarkOnly: 'bookMarkOnly',
      organizationId: 'organizationId',
    };
    const url = `${this.baseUrl}/${this.driversUrl}?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getDriverProfile(id: string): Observable<DriverProfile[]> {
    const url = `${this.baseUrl}/${this.driversUrl}/${id}`;
    return this.http.get<DriverProfile[]>(url);
  }

  getDriverScoring(driverGuid: string): Observable<any> {
    const url = `${this.baseUrl}/${this.driverScoringUrl}/${driverGuid}`;
    return this.http.get<DriverProfile[]>(url);
  }

  getScoringTier(): Observable<any> {
    const organizationId = this.selectedOrganization?.organizationId;
    const url = `${this.baseUrl}/scoring/tiers/settings?RefId=0&organizationId=${organizationId}`;
    return this.http.get<any>(url);
  }

  getScoreHistory(params: any): Observable<any> {
    const paramMappings: any = {
      driverGuid: 'driverGuid',
      startDate: 'startDate',
      endDate: 'endDate',
    };
    const url = `${this.baseUrl}/${this.driverScoringUrl}?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getFiltersList(filterName: string): Observable<any> {
    const organizationId = this.selectedOrganization?.organizationId;
    const url = `${this.baseUrl}/${this.filterListUrl}?listType=${filterName}&organizationId=${organizationId}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => {
        if (key === 'driverScoreMax' && request[key] === 100) {
          return false;
        }
        return request[key];
      })
      .map((key) => {
        if (key === 'driverScoreMin' || key === 'driverScoreMax') {
          return `${paramMappings[key]}=${request[key] * 10}`;
        } else {
          return `${paramMappings[key]}=${request[key]}`;
        }
      })
      .join('&');
  }

  upsertAccident(request: any): Observable<any> {
    if (request && request.id) {
      return this.http.put(`${this.baseUrl}/${this.accidentUrl}`, request);
    } else {
      return this.http.post(`${this.baseUrl}/${this.accidentUrl}`, request);
    }
  }

  upsertFollow(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/drivers/user`, request);
  }

  upsertInjury(request: any): Observable<any> {
    if (request && request.id) {
      return this.http.put(`${this.baseUrl}/${this.injuryUrl}`, request);
    } else {
      return this.http.post(`${this.baseUrl}/${this.injuryUrl}`, request);
    }
  }

  upsertHOS(request: any): Observable<any> {
    if (request && request.id) {
      return this.http.put(`${this.baseUrl}/${this.hosUrl}`, request);
    } else {
      return this.http.post(`${this.baseUrl}/${this.hosUrl}`, request);
    }
  }

  getAccidentOptionsList(entityType: string): Observable<any> {
    const organizationId = this.selectedOrganization?.organizationId;
    const url = `${this.baseUrl}/${this.accidentUrl}/options/list?entityType=${entityType}&organizationId=${organizationId}`;
    return this.http.get<any>(url);
  }
}
