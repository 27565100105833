import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() required?: boolean;
  @Input() value: string = '';

  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  @Output() dateChanged: EventEmitter<string> = new EventEmitter();

  onDateChange(event: any): void {
    if (!event.value) return;

    const date: Date = event.value;
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    // this.value = formattedDate;
    this.dateChanged.emit(event.value);
  }
}
