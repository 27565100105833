<mat-form-field appearance="outline">
  <mat-label>
    {{ label }}
    <span class="star" *ngIf="required">*</span>
  </mat-label>

  <input
    matInput
    [matDatepicker]="picker"
    [disabled]="disabled"
    [min]="minDate"
    [max]="maxDate"
    [value]="value"
    (dateChange)="onDateChange($event)"
  />

  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

  <mat-datepicker #picker [disabled]="disabled"></mat-datepicker>
</mat-form-field>
