import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalMode } from '@app/models/general.model';
import { ComplianceService } from '@app/services/compliance.service';
import { DriverNotesService } from '@app/services/driver-notes.service';
import { ToasterService } from '@app/services/toaster.service';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { FilesViewerModalComponent } from '@app/shared/components/files-viewer-modal/files-viewer-modal.component';
import {
  convertDateFormat,
  convertFromDatePickerToDatabaseFormat,
} from '@app/shared/helpers/date.helper';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';

@Component({
  selector: 'app-new-file-modal',
  templateUrl: './new-file-modal.component.html',
  styleUrls: ['./new-file-modal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none', opacity: 0 })
      ),
      state('expanded', style({ height: '*', display: 'block', opacity: 1 })),
      transition('expanded <=> collapsed', [
        style({ display: 'block' }),
        animate('225ms ease-in-out'),
      ]),
    ]),
  ],
})
export class NewFileModalComponent implements OnInit {
  urls: string[] = [];

  fileHistory: any[] = [];
  recordTypes: any[] = [];

  showUploadNewFile = true;

  displayedColumns: string[] = [
    'expand',
    'view',
    'uploaded',
    'expires',
    'current',
    'uploadedBy',
  ];

  recordTypeSelected: any = null;
  expirationDate: string = '';
  expirationDateRequired: boolean = false;
  expandedElement!: any;

  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  userInfo!: any;
  avatarUrl!: string;
  mode: ModalMode = ModalMode.CREATE;
  modalMode = ModalMode;
  isLoading: boolean = false;
  isLoadingRecordTypeHistory: boolean = false;

  noteContent: string = '';

  selectedOrganization: any = {};

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;
  convertDateFormat = convertDateFormat;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NewFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toasterService: ToasterService,
    private complianceService: ComplianceService,
    private driverNotesService: DriverNotesService
  ) {}

  ngOnInit(): void {
    this.setShownUploadNewFile();
    this.loadSelectedOrganization();
    if (this.data.mode === ModalMode.EDIT) {
      this.mode = this.data.mode;
      this.displayedColumns.push('actions');
      this.fetchRecordTypeHistory();
    }

    if (!this.showUploadNewFile) {
      this.displayedColumns = this.displayedColumns.filter((col) => col !== 'actions');
    }
    this.loadLoggedUser();
    this.loadRecordTypes();
  }

  setShownUploadNewFile(): void {
    if (this.data.hideUploadNewFile) {
      this.showUploadNewFile = false;
    }
  }

  fetchRecordTypeHistory(recordTypeId?: string): void {
    this.isLoadingRecordTypeHistory = true;
    const params = {
      driverGuid: this.data.driverGuid,
      recordTypeId: recordTypeId ? recordTypeId : this.data.recordTypeId,
    };

    this.complianceService.getRecordTypeHistory(params).subscribe({
      next: (response) => {
        this.fileHistory = (response || []).map((record: any) => ({
          ...record,
          uploaded: this.formatDate(record.uploaded),
          uploadedBy: record.uploadedBy
            ? record.uploadedBy.replace(/\b\w/g, (char: string) => char.toUpperCase())
            : 'Unknown',
          isExpanded: false,
        }));
        this.isLoadingRecordTypeHistory = false;
      },
      error: (error) => {
        console.error(error);
        this.toasterService.showError('Failed to load record type history');
        this.isLoadingRecordTypeHistory = false;
      },
    });
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const datePart = dateString.split(' ')[0];
    return datePart;
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  loadRecordTypes(): void {
    this.isLoading = true;
    const params = {
      organizationId: this.selectedOrganization.organizationId,
    };

    this.complianceService.getOrganizationRecordTypeSettings(params).subscribe({
      next: (response) => {
        const categories = response.categories || [];
        this.recordTypes = this.extractRecordTypes(categories).filter(
          (rt: any) => rt.archive === false
        );

        this.recordTypes.sort((a, b) => a.recordTypeName.localeCompare(b.recordTypeName));
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.toasterService.showError('Failed to load record types');
      },
    });
  }

  extractRecordTypes(categories: any[]): any[] {
    let recordTypesArray: any[] = [];

    categories.forEach((category) => {
      if (category.recordTypes && category.recordTypes.length > 0) {
        recordTypesArray = recordTypesArray.concat(category.recordTypes);
      }
    });

    return recordTypesArray;
  }

  makeCurrent(row: any): void {
    if (row.current) {
      return;
    }

    const requestPayload = {
      driverGuid: this.data.driverGuid,
      fileId: row.id,
    };

    this.complianceService.updateDriverFileCurrentStatus(requestPayload).subscribe({
      next: () => {
        this.toasterService.showSuccess('The file has been set as current successfully.');
        this.fetchRecordTypeHistory();
        this.complianceService.notifyFileRefresh();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  deleteFile(row: any): void {
    const data = {
      title: `Delete File`,
      text: `Are you sure you want to delete the File?`,
      yesButtonText: 'Delete',
      noButtonText: 'Cancel',
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const driverGuid = this.data.driverGuid;
          const fileId = row.id;
          this.complianceService.deleteDriverFile(driverGuid, fileId).subscribe({
            next: () => {
              this.toasterService.showSuccess('The File was deleted successfully');
              this.fetchRecordTypeHistory();
            },
            error: (error) => {
              console.error(error);
            },
          });
        }
      });
  }

  onMenuClick(event: Event): void {
    event.stopPropagation();
  }

  loadLoggedUser(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userInfo = parsedData.userInfo;
      this.avatarUrl = this.userInfo.previewUrl;
    }
  }

  onSave(): void {
    if (this.selectedFile) {
      const hasNote = !!this.noteContent.trim();
      const params = {
        driverGuid: this.data.driverGuid,
        recordTypeId: this.recordTypeSelected?.recordTypeId
          ? this.recordTypeSelected.recordTypeId
          : this.data.recordTypeId,
        organizationId: this.selectedOrganization.organizationId,
        expiration: this.expirationDate
          ? convertFromDatePickerToDatabaseFormat(this.expirationDate)
          : '',
        uploadedBy: this.userInfo.objectId,
        note: hasNote,
      };
      const formData = new FormData();
      formData.append(`file`, this.selectedFile, this.selectedFile.name);
      this.complianceService.uploadDriverFile(params, formData).subscribe({
        next: (res) => {
          this.createNote(res);
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  createNote(response: any): void {
    if (!this.noteContent.trim()) {
      this.dialogRef.close(true);
      return;
    }

    const entityId = response.id;
    const entityType = 'driver_qfile';
    const userId = this.userInfo.objectId;

    const noteRequest = {
      id: null,
      entity_id: entityId,
      entity_type: entityType,
      user_id: userId,
      createdBy: userId,
      lastModifiedBy: userId,
      message: this.noteContent,
      images: 0,
      deleted: 0,
    };

    this.driverNotesService.createNote(noteRequest).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onRecordTypeChange(selectedRecordType: any): void {
    this.recordTypeSelected = selectedRecordType;
    this.expirationDate = '';

    this.expirationDateRequired = selectedRecordType.expiration;

    if (this.mode === ModalMode.CREATE && selectedRecordType?.recordTypeId) {
      this.fetchRecordTypeHistory(selectedRecordType?.recordTypeId);
    }
  }

  updateExpirationDate(expirationDate: string): void {
    this.expirationDate = expirationDate;
  }

  toggleRow(element: any): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    element.isExpanded = !element.isExpanded;
  }

  onIconClick(record: any) {
    const sanitizedRecordTypeName = this.data.recordTypeName.replace(/\s+/g, '_');
    const fileExtension = record.fileExtension || 'pdf';
    const fileName = `${sanitizedRecordTypeName}.${fileExtension}`;

    this.complianceService.getDriverQFile(this.data.driverGuid, record.id).subscribe({
      next: (response) => {
        const blob = new Blob([response], { type: `application/${fileExtension}` });
        const file = new File([blob], fileName, {
          type: `application/${fileExtension}`,
        });
        const blobUrl = window.URL.createObjectURL(file);
        const urlWithFilename = `${blobUrl}#filename=${fileName}`;
        this.urls = [urlWithFilename];
        this.openFilesViewerModal();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openFilesViewerModal() {
    const dialogRef = this.dialog.open(FilesViewerModalComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      data: { urls: this.urls, currentIndex: 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  //Upload file functions
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  handleFile(file: File): void {
    this.selectedFile = file;

    const reader = new FileReader();
    reader.onload = () => {
      this.previewUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.handleFile(file);
      // Ensure expiration date logic is applied if in EDIT mode
      if (this.mode === ModalMode.EDIT && this.data?.expiration) {
        this.expirationDateRequired = true;
      }
    }
  }

  getFileSize(sizeInBytes: number): string {
    const sizeInMB = sizeInBytes / 1024 / 1024;
    if (sizeInMB < 1) {
      const sizeInKB = sizeInBytes / 1024;
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInMB.toFixed(2)} MB`;
    }
  }

  getFileIcon(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'fas fa-file-pdf icon-big';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'fas fa-file-image icon-big';
      default:
        return 'fas fa-file icon-big';
    }
  }

  setFullName(user: any): string {
    return `${user?.firstName} ${user?.lastName}`;
  }

  isSaveDisabled(): boolean {
    if (this.expirationDateRequired && !this.expirationDate) {
      return true;
    }

    if (this.mode === ModalMode.CREATE) {
      return !(this.recordTypeSelected && this.selectedFile);
    }

    if (this.mode === ModalMode.EDIT) {
      return !this.selectedFile;
    }

    return false;
  }
}
