import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NgSelectModule } from '@ng-select/ng-select';
import { QuillEditorComponent } from 'ngx-quill';
import { NgChartsModule } from 'ng2-charts';

import { DriversListComponent } from './drivers-list/drivers-list.component';
import { DialogActivityComponent } from './dialog-activity/dialog-activity.component';
import { DialogDriverComponent } from '@app/drivers/dialog-driver/dialog-driver.component';
import { TabSectionsInfoComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-info/tab-sections-info.component';
import { TabSectionsFilesComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-files/tab-sections-files.component';
import { TabSectionsActivityComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-activity/tab-sections-activity.component';
import { TabSectionsInterventionComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-intervention/tab-sections-intervention.component';
import { TabSectionsNotesComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-notes/tab-sections-notes.component';
import { DialogDriverAccidentComponent } from '@app/drivers/dialog-driver/dialog-driver-accident/dialog-driver-accident.component';
import { DialogDriverInjuryComponent } from '@app/drivers/dialog-driver/dialog-driver-injury/dialog-driver-injury.component';
import { DialogDriverTelematicsComponent } from '@app/drivers/dialog-driver/dialog-driver-telematics/dialog-driver-telematics.component';
import { DialogDriverHoursOfServiceComponent } from '@app/drivers/dialog-driver/dialog-driver-hours-of-service/dialog-driver-hours-of-service.component';
import { DialogDriverInspectionComponent } from '@app/drivers/dialog-driver/dialog-driver-inspection/dialog-driver-inspection.component';
import { DialogDriverSpeedComponent } from '@app/drivers/dialog-driver/dialog-driver-speed/dialog-driver-speed.component';
import { TelematicsListComponent } from './dialog-driver/tab-sections/tab-sections-activity/telematics-list/telematics-list.component';
import { SpeedListComponent } from './dialog-driver/tab-sections/tab-sections-activity/speed-list/speed-list.component';
import { HoursServiceListComponent } from './dialog-driver/tab-sections/tab-sections-activity/hours-service-list/hours-service-list.component';
import { AccidentsListComponent } from './dialog-driver/tab-sections/tab-sections-activity/accidents-list/accidents-list.component';
import { InjuryListComponent } from './dialog-driver/tab-sections/tab-sections-activity/injury-list/injury-list.component';
import { DriversRoutingModule } from './drivers-routing.module';
import { ImgFallbackModule } from '@app/shared/directives/img-fallback/img-fallback.module';
import { EditorModule } from '@app/shared/components/editor/editor.module';
import { DialogConfirmModule } from '@app/shared/components/dialog-confirm/dialog-confirm.module';
import { InputModule } from '@app/shared/components/input/input.module';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { MultiSelectModule } from '@app/shared/components/multi-select/multi-select.module';
import { SelectModule } from '@app/shared/components/select/select.module';
import { TextareaModule } from '@app/shared/components/textarea/textarea.module';
import { TextareaReactiveModule } from '@app/shared/components/textarea-reactive/textarea-reactive.module';
import { RadioButtonModule } from '@app/shared/components/radio-button/radio-button.module';
import { RadioButtonReactiveModule } from '@app/shared/components/radio-button-reactive/radio-button-reactive.module';
import { DateTimePickerModule } from '@app/shared/components/date-time-picker/date-time-picker.module';
import { DialogFileUploadModule } from '@app/shared/components/dialog-file-upload/dialog-file-upload.module';
import { InspectionListComponent } from './dialog-driver/tab-sections/tab-sections-activity/inspection-list/inspection-list.component';
import { TimePickerModule } from '@app/shared/components/time-picker/time-picker.module';
import { DateRangePickerModule } from '@app/shared/components/date-range-picker/date-range-picker.module';
import { SeatbeltListComponent } from './dialog-driver/tab-sections/tab-sections-activity/seatbelt-list/seatbelt-list.component';
import { NotesModule } from '@app/shared/components/notes/notes.module';
import { DriversFiltersModalComponent } from './drivers-list/drivers-filters-modal/drivers-filters-modal.component';
import { ScoreHistoryModalComponent } from './dialog-driver/tab-sections/tab-sections-info/score-history-modal/score-history-modal.component';
import { DatetimePickerModule } from '@app/shared/components/datetime-picker/datetime-picker.module';
import { DialogDriverPercentageSpeedingComponent } from './dialog-driver/dialog-driver-percentage-speeding/dialog-driver-percentage-speeding.component';
import { PercentageSpeedingListComponent } from './dialog-driver/tab-sections/tab-sections-activity/percentage-speeding-list/percentage-speeding-list.component';
import { TabSectionsEventsComponent } from './dialog-driver/tab-sections/tab-sections-events/tab-sections-events.component';
import { DialogDriverEventsFiltersComponent } from './dialog-driver/dialog-driver-events-filters/dialog-driver-events-filters.component';
import { EventTableComponent } from './dialog-driver/tab-sections/tab-sections-events/event-table/event-table.component';
import { StatusSelectModule } from '@app/shared/components/status-select/status-select.module';
import { StarRatingModule } from '@app/shared/components/star-rating/star-rating.module';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';
import { DialogEventModule } from '@app/shared/components/dialog-event/dialog-event.module';
import { DialogCoachingDetailsModule } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.module';
import { DialogFilterValuesModule } from '@app/shared/components/dialog-filter-values/dialog-filter-values.module';
import { MultiSelectWithChipsModule } from '@app/shared/components/multi-select-with-chips/multi-select-with-chips.module';
import { NewFileModalComponent } from './dialog-driver/tab-sections/new-file-modal/new-file-modal.component';
import { PrintAccidentDataComponent } from './dialog-driver/dialog-driver-accident/print-accident-data/print-accident-data.component';
import { PrintInjuryDataComponent } from './dialog-driver/dialog-driver-injury/print-injury-data/print-injury-data.component';
import { DialogNewHosModule } from '@app/shared/components/dialog-new-hos/dialog-new-hos.module';

@NgModule({
  declarations: [
    DriversListComponent,
    DialogActivityComponent,
    DialogDriverComponent,
    TabSectionsInfoComponent,
    TabSectionsFilesComponent,
    TabSectionsActivityComponent,
    TabSectionsInterventionComponent,
    TabSectionsNotesComponent,
    DialogDriverAccidentComponent,
    DialogDriverInjuryComponent,
    DialogDriverTelematicsComponent,
    DialogDriverHoursOfServiceComponent,
    DialogDriverInspectionComponent,
    DialogDriverSpeedComponent,
    TelematicsListComponent,
    SpeedListComponent,
    HoursServiceListComponent,
    AccidentsListComponent,
    InjuryListComponent,
    InspectionListComponent,
    SeatbeltListComponent,
    DriversFiltersModalComponent,
    ScoreHistoryModalComponent,
    DialogDriverPercentageSpeedingComponent,
    PercentageSpeedingListComponent,
    TabSectionsEventsComponent,
    DialogDriverEventsFiltersComponent,
    EventTableComponent,
    NewFileModalComponent,
    PrintAccidentDataComponent,
    PrintInjuryDataComponent,
  ],
  imports: [
    UtcDatePipe,
    CommonModule,
    DriversRoutingModule,
    MatTableModule,
    MatIconModule,
    MatSortModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    ImgFallbackModule,
    QuillEditorComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    EditorModule,
    DialogConfirmModule,
    NgSelectModule,
    InputModule,
    CheckboxModule,
    MultiSelectModule,
    SelectModule,
    TextareaModule,
    TextareaReactiveModule,
    RadioButtonModule,
    RadioButtonReactiveModule,
    DateTimePickerModule,
    DatetimePickerModule,
    DialogFileUploadModule,
    TimePickerModule,
    DateRangePickerModule,
    NotesModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgChartsModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    MatExpansionModule,
    MatSliderModule,
    StatusSelectModule,
    StarRatingModule,
    DialogEventModule,
    DialogCoachingDetailsModule,
    DialogFilterValuesModule,
    MultiSelectWithChipsModule,
    MatSlideToggleModule,
    DialogNewHosModule,
  ],
})
export class DriversModule {}
