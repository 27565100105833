<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div *ngIf="mode === modalMode.CREATE" mat-dialog-title>New File</div>
  <div *ngIf="mode === modalMode.EDIT" mat-dialog-title>{{ data?.recordType }}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div *ngIf="mode === modalMode.CREATE" class="text">
    <span>
      Please select a Record Type and optional Expiration Date, then upload the file.
    </span>
  </div>
  <div *ngIf="mode === modalMode.CREATE" class="input-row">
    <!-- Record Type -->
    <mat-form-field class="input-field" appearance="outline">
      <mat-label>Record Type</mat-label>
      <mat-select
        [(ngModel)]="recordTypeSelected"
        (selectionChange)="onRecordTypeChange($event.value)"
      >
        <mat-option *ngFor="let recordType of recordTypes" [value]="recordType">
          {{ recordType.recordTypeName }}
        </mat-option>
      </mat-select>
      <mat-progress-spinner
        *ngIf="isLoading"
        matSuffix
        mode="indeterminate"
        diameter="20"
        class="spinner dropdown-spinner"
      ></mat-progress-spinner>
    </mat-form-field>

    <!-- Expiration Date -->
    <div
      ngClass="expiration-date-picker"
      *ngIf="recordTypeSelected?.expiration"
      class="input-field"
    >
      <app-date-time-picker
        [label]="'Expiration Date'"
        [hideTime]="true"
        [value]="expirationDate"
        (dateChanged)="updateExpirationDate($event)"
      ></app-date-time-picker>
    </div>
  </div>

  <!-- File History -->
  <div *ngIf="recordTypeSelected || mode === modalMode.EDIT" class="file-history">
    <h3>File History</h3>
    <div class="container-table">
      <table
        mat-table
        multiTemplateDataRows
        [dataSource]="fileHistory"
        class="file-history-table"
      >
        <!-- Expandable Row -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="toggleRow(element)"
              class="icon"
              *ngIf="element.note"
            >
              <mat-icon *ngIf="expandedElement !== element">arrow_right</mat-icon>
              <mat-icon *ngIf="expandedElement === element">arrow_drop_down </mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- View Column -->
        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>View</th>
          <td mat-cell *matCellDef="let row">
            <span class="pdf-icon" (click)="onIconClick(row)">
              <i class="fas fa-file-pdf clickable"></i>
            </span>
          </td>
        </ng-container>

        <!-- Uploaded Column -->
        <ng-container matColumnDef="uploaded">
          <th mat-header-cell *matHeaderCellDef>Uploaded</th>
          <td mat-cell *matCellDef="let row">{{ row.uploaded }}</td>
        </ng-container>

        <!-- Expires Column -->
        <ng-container matColumnDef="expires">
          <th mat-header-cell *matHeaderCellDef>Expires</th>
          <td mat-cell *matCellDef="let row">
            {{ row.expires ? convertDateFormat(row.expires) : "-" }}
          </td>
        </ng-container>

        <!-- Current Column -->
        <ng-container matColumnDef="current">
          <th mat-header-cell *matHeaderCellDef>Current</th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox [checked]="row.current" disabled></mat-checkbox>
          </td>
        </ng-container>

        <!-- Uploaded By Column -->
        <ng-container matColumnDef="uploadedBy">
          <th mat-header-cell *matHeaderCellDef>Uploaded By</th>
          <td mat-cell *matCellDef="let row">{{ row.uploadedBy }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container
          *ngIf="mode === modalMode.EDIT && showUploadNewFile"
          matColumnDef="actions"
        >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <i
              class="fas fa-ellipsis clickable"
              [matMenuTriggerFor]="menu"
              (click)="onMenuClick($event)"
            ></i>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="makeCurrent(row)" [disabled]="row.current">
                <span>Make Current</span>
              </button>
              <button mat-menu-item (click)="deleteFile(row)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Expanded Content Column -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
            class="subtable-element"
          >
            <div
              class="shipper-element-detail"
              [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
            >
              <ng-container *ngIf="expandedElement === element">
                <app-notes
                  [avatarUrl]="''"
                  [contents]="''"
                  [showEditor]="false"
                  [showTitle]="false"
                  [entityId]="element.id"
                  [sectionId]="'notes'"
                  [entityType]="'driver_qfile'"
                ></app-notes>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="nested-detail-row no-hover"
        ></tr>
      </table>
      <div
        *ngIf="!isLoadingRecordTypeHistory && fileHistory.length === 0"
        class="no-records-found"
      >
        <p>No records found.</p>
      </div>
      <div *ngIf="isLoadingRecordTypeHistory" class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
    </div>
  </div>

  <!-- Upload New File -->
  <div
    *ngIf="(recordTypeSelected || mode === modalMode.EDIT) && showUploadNewFile"
    class="upload-new-file"
  >
    <h3>Upload New File</h3>
    <div class="upload-new-file-container">
      <!-- Uploaded File Info -->
      <div *ngIf="selectedFile" class="uploaded-file-info">
        <i [ngClass]="getFileIcon(selectedFile.name)"></i>
        <div class="file-info-text">
          <span class="file-name-text">{{ selectedFile.name }}</span>
          <span class="file-size-text">{{ getFileSize(selectedFile.size) }}</span>
        </div>
      </div>

      <!-- Expiration Date Picker (shown if in EDIT mode and expiration is true) -->
      <div
        ngClass="expiration-date-picker expiration-date-picker-margin"
        *ngIf="expirationDateRequired && mode === modalMode.EDIT"
        class="input-field"
      >
        <app-date-time-picker
          [label]="'Expiration Date'"
          [hideTime]="true"
          [value]="expirationDate"
          (dateChanged)="updateExpirationDate($event)"
        ></app-date-time-picker>
      </div>

      <!-- Note Section -->
      <div *ngIf="selectedFile" class="note-section">
        <div class="initials" [ngClass]="getBackgroundClass(setFullName(userInfo))">
          {{ getInitials(setFullName(userInfo)) }}
        </div>
        <div class="note-input-container">
          <input
            type="text"
            placeholder="Enter note here... (optional)"
            class="note-input"
            [(ngModel)]="noteContent"
          />
        </div>
      </div>

      <!-- Drag & Drop -->
      <div
        class="drag-drop-container"
        [ngClass]="{ 'no-border': !selectedFile }"
        (click)="fileInput.click()"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
      >
        <div class="icon-text-container">
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            style="display: none"
            accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, .ppt, .pptx"
          />
          <i class="fa-solid fa-cloud-arrow-up"></i>
          <div class="drag-drop-text-container">
            <p *ngIf="!selectedFile" class="drag-drop-main-text">Drag & Drop File</p>
            <p *ngIf="selectedFile" class="drag-drop-main-text">
              Drag & Drop to Replace File
            </p>
            <small class="drag-drop-secondary-text">or Select a File...</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <!-- EDIT and CREATE buttons -->
  <button
    *ngIf="showUploadNewFile"
    mat-flat-button
    color="primary"
    (click)="onSave()"
    [disabled]="isSaveDisabled()"
  >
    <i class="far fa-circle-check"></i> Save
  </button>
  <button *ngIf="showUploadNewFile" mat-button (click)="onCancel()">
    <i class="fas fa-ban"></i> Cancel
  </button>

  <!-- VIEW button -->
  <button *ngIf="!showUploadNewFile" mat-flat-button color="primary" (click)="onCancel()">
    Close
  </button>
</mat-dialog-actions>
