import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IHoursService } from '@app/models/hours-service.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  convertDateFormat,
  convertToHourMinuteFormat,
} from '@app/shared/helpers/date.helper';

interface ITableElement {
  label: string;
  value: string;
}

@Component({
  selector: 'app-dialog-driver-hours-of-service',
  templateUrl: './dialog-driver-hours-of-service.component.html',
  styleUrls: ['./dialog-driver-hours-of-service.component.scss'],
})
export class DialogDriverHoursOfServiceComponent implements OnInit, OnDestroy {
  hoursServiceRowData!: IHoursService;
  hoursServiceDetail!: any;
  activityDetails!: ITableElement[];
  hosSummary!: ITableElement[];
  violations!: ITableElement[];
  isLoading: boolean = true;
  columns: string[] = ['label', 'value'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IHoursService,
    private dialogInfoService: DialogDriverService
  ) {
    this.hoursServiceRowData = data;
  }

  ngOnInit(): void {
    if (this.hoursServiceRowData) {
      this.dialogInfoService
        .getHoursServiceDetail(this.hoursServiceRowData.id)
        .subscribe({
          next: (response: any) => {
            this.hoursServiceDetail = response[0];
            this.setActivityDetails();
            this.setHosSummary();
            this.setViolations();
            this.isLoading = false;
          },
          error: (error: any) => {
            console.error('Error:', error);
            this.setActivityDetails();
            this.setHosSummary();
            this.setViolations();
            this.isLoading = false;
          },
        });
    }
  }

  ngOnDestroy(): void {
    const url = new URL(window.location.href);
    url.searchParams.delete('activity');
    url.searchParams.delete('eventId');

    window.history.replaceState({}, '', url.toString());
  }

  setViolations(): void {
    if (this.hoursServiceDetail?.violations) {
      this.violations = this.hoursServiceDetail.violations.map((violation: any) => ({
        label: violation.standardViolationType,
        value: violation.violationReason,
      }));
    } else {
      this.violations = [];
    }
  }

  setHosSummary(): void {
    this.hosSummary = [
      {
        label: 'Off Duty',
        value: this.hoursServiceDetail?.offDuty
          ? convertToHourMinuteFormat(this.hoursServiceDetail.offDuty)
          : '',
      },
      {
        label: 'Sleeper Birth',
        value: this.hoursServiceDetail?.sleeper
          ? convertToHourMinuteFormat(this.hoursServiceDetail.sleeper)
          : '',
      },
      {
        label: 'Driving',
        value: this.hoursServiceDetail?.driving
          ? convertToHourMinuteFormat(this.hoursServiceDetail.driving)
          : '',
      },
      {
        label: 'On Duty',
        value: this.hoursServiceDetail?.onDuty
          ? convertToHourMinuteFormat(this.hoursServiceDetail.onDuty)
          : '',
      },
      {
        label: 'Personal Conveyance',
        value: this.hoursServiceDetail?.personalConveyance
          ? convertToHourMinuteFormat(this.hoursServiceDetail.personalConveyance)
          : '',
      },
    ];
  }

  setActivityDetails(): void {
    this.activityDetails = [
      {
        label: 'Date',
        value: this.hoursServiceDetail?.summaryDate
          ? convertDateFormat(this.hoursServiceDetail.summaryDate)
          : '',
      },
      {
        label: 'Day',
        value: this.hoursServiceDetail?.day ? this.hoursServiceDetail.day : '',
      },
      {
        label: 'Driver ID',
        value: this.hoursServiceDetail?.driverId ? this.hoursServiceDetail.driverId : '',
      },
      // {
      //   label: 'Vehicle ID',
      //   value: this.hoursServiceDetail?.vehicleId
      //     ? this.hoursServiceDetail.vehicleId
      //     : '',
      // },
      {
        label: 'Source',
        value: this.hoursServiceDetail?.source ? this.hoursServiceDetail.source : '',
      },
    ];
  }
}
