import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, Subject, takeUntil } from 'rxjs';

import { DriverService } from '@app/services/driver.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  convertToHourMinuteFormat,
  formatDate,
  formatDateWithSlash,
} from '@app/shared/helpers/date.helper';
import { toTitleCase } from '@app/shared/helpers/string.helper';

enum VIOLATION {
  ADVERSE_DRIVING_CONDITIONS = 'Adverse Driving Conditions',
  MISUSE_OF_PERSONAL_CONVEYANCE = 'Misuse of Personal Conveyance',
  UNASSIGNED_DRIVE_TIME = 'Unassigned Drive Time',
}

interface ITableElement {
  label: string;
  value: string;
}

@Component({
  selector: 'app-dialog-new-hos',
  templateUrl: './dialog-new-hos.component.html',
  styleUrls: ['./dialog-new-hos.component.scss'],
})
export class DialogNewHosComponent implements OnInit, OnDestroy {
  step = 1;
  hosForm!: FormGroup;

  showExistsModal = false;
  newViolation: any = null;
  isLoading: boolean = false;

  activityDetails!: ITableElement[];
  hosSummary!: ITableElement[];
  violations!: ITableElement[];
  columns: string[] = ['label', 'value'];

  userInfo!: any;
  source = 'Brocade';

  public minDate!: Date;
  public maxDate!: Date;

  private allHosSummaries: any[] = [];
  private destroy$ = new Subject<void>();

  violationOptions: string[] = [
    VIOLATION.ADVERSE_DRIVING_CONDITIONS,
    VIOLATION.MISUSE_OF_PERSONAL_CONVEYANCE,
    VIOLATION.UNASSIGNED_DRIVE_TIME,
  ];

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogNewHosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogDriverService: DialogDriverService
  ) {}

  ngOnInit(): void {
    this.setDates();
    this.loadLoggedUser();
    this.initForm();
    this.fetchAllHosSummariesOnInit();
  }

  setDates(): void {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 90);
    this.maxDate = new Date();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fetchAllHosSummariesOnInit(): void {
    this.isLoading = true;

    const today = new Date();

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 90);

    const requestSummary = {
      startDate,
      endDate: today,
      driverGUID: this.data?.driverGuid,
      page: 1,
      pageSize: 9999,
    };

    this.dialogDriverService.getHoursServiceSummary(requestSummary).subscribe({
      next: (response: any) => {
        const data = response[0];
        this.allHosSummaries = data?.hosSummaries || [];

        if (this.allHosSummaries.length) {
          const summaryDates: string[] = this.allHosSummaries.map((s) => s.summaryDate);

          const minSummaryDate: string = summaryDates.reduce((prev, curr) =>
            prev < curr ? prev : curr
          );
          const maxSummaryDate: string = summaryDates.reduce((prev, curr) =>
            prev > curr ? prev : curr
          );

          const [minYear, minMonth, minDay] = minSummaryDate.split('-').map(Number);
          const [maxYear, maxMonth, maxDay] = maxSummaryDate.split('-').map(Number);

          this.minDate = new Date(minYear, minMonth - 1, minDay);
          this.maxDate = new Date(maxYear, maxMonth - 1, maxDay);
        }

        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error fetching HOS summaries on init:', err);
        this.isLoading = false;
      },
    });
  }

  loadLoggedUser(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userInfo = parsedData.userInfo;
    }
  }

  initForm(): void {
    this.hosForm = this.fb.group({
      hosViolation: [null, Validators.required],
      violationDate: [null, Validators.required],
      description: ['', Validators.required],
    });
  }

  confirmAddViolation(): void {
    const userId = this.userInfo?.objectId || 'unknown';

    const payload: any = {
      DriverId: this.data.driver.driverId,
      ExternalDriverId: 'none',
      FromDate: formatDate(this.newViolation.date),
      ToDate: formatDate(this.newViolation.date),
      Type: 'none',
      Reason: this.newViolation.description || 'No reason provided',
      StandardType: this.newViolation.type,
      Source: this.source,
      OrganizationId: this.data.driver.organizationId,
      CreatedBy: userId,
    };

    this.driverService.upsertHOS(payload).subscribe({
      next: () => {
        this.closeDialog();
        this.confirmCloseDialog();
      },
      error: (error) => {
        console.error('Error adding the violation', error);
      },
    });
  }

  confirmCloseDialog(): void {
    const data = {
      title: 'HOS Violation Added',
      text: `The ${this.newViolation?.type} violation has been added to driver
      <strong>${toTitleCase(
        this.data.driver.driverName
      )}</strong> on <strong>${formatDateWithSlash(this.newViolation?.date)}</strong>.
      If the ${this.newViolation?.type} violation is included in the event rules,
      then an event will be created.`,
      confirm: true,
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '465px' })
      .afterClosed()
      .pipe(takeUntil(this.destroy$));
  }

  closeModal(): void {
    this.showExistsModal = false;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  onDialogCancelClick(): void {
    if (this.hosForm.dirty) {
      const data = {
        title: 'Unsaved changes',
        text: 'You have unsaved changes. Are you sure you want to leave this page? The changes you made will not be saved.',
      };
      this.dialog
        .open(DialogConfirmComponent, { data, width: '500px' })
        .afterClosed()
        .pipe(filter((closeModal: boolean) => closeModal))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  onDialogBackClick(): void {
    this.step = 1;
  }

  private setupLocalViolationData(hosViolation: string, violationDate: Date): void {
    this.newViolation = {
      type: hosViolation,
      date: violationDate,
      description: this.hosForm.value.description,
    };

    const dateVal = new Date(violationDate);
    const dayName = dateVal.toLocaleDateString('en-US', { weekday: 'long' });

    this.activityDetails = [
      { label: 'Date', value: formatDateWithSlash(violationDate) },
      { label: 'Day', value: dayName },
      { label: 'Driver Id', value: this.data?.driver?.driverId ?? 'N/A' },
      { label: 'Source', value: this.source },
    ];
  }

  onNextClick(): void {
    const { hosViolation, violationDate } = this.hosForm.value;
    const dbDate = formatDate(violationDate);
    const found = this.allHosSummaries.find((s: any) => s.summaryDate === dbDate);
    if (!found) {
      this.hosForm.get('violationDate')?.setErrors({ noHosForThisDay: true });
      return;
    }

    const standardType = hosViolation;
    const alreadyExists = found.violations?.some(
      (v: any) => v.standardViolationType === standardType
    );

    if (alreadyExists) {
      this.showExistsModal = true;
      return;
    } else {
      this.showExistsModal = false;
    }

    this.setupLocalViolationData(hosViolation, violationDate);
    this.fetchHosDataAndProceed(violationDate);
  }

  private fetchHosDataAndProceed(violationDate: Date): void {
    this.isLoading = true;

    if (this.allHosSummaries.length) {
      this.handleHosSummaryData(violationDate, this.allHosSummaries);
      return;
    }

    const today = new Date();

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 90);

    const requestSummary = {
      startDate,
      endDate: today,
      driverGUID: this.data?.driverGuid,
      page: 1,
      pageSize: 9999,
    };

    this.dialogDriverService.getHoursServiceSummary(requestSummary).subscribe({
      next: (response: any) => {
        const data = response[0];
        this.allHosSummaries = data?.hosSummaries || [];
        this.handleHosSummaryData(violationDate, this.allHosSummaries);
      },
      error: (err) => {
        console.error('Error fetchHOSData: ', err);
        this.isLoading = false;
      },
    });
  }

  private handleHosSummaryData(violationDate: Date, summaries: any[]): void {
    const dbDate = formatDate(violationDate);
    const found = summaries.find((s: any) => s.summaryDate === dbDate);

    if (!found) {
      console.warn('No HOS summary found for date', violationDate);
      this.hosSummary = [];
      this.violations = [];
      this.step = 2;
      this.isLoading = false;
      return;
    }

    this.dialogDriverService.getHoursServiceDetail(found.id).subscribe({
      next: (detailResp: any) => {
        if (!detailResp || !detailResp.length) {
          this.hosSummary = [];
          this.violations = [];
        } else {
          const detailObj = detailResp[0];
          this.hosSummary = [
            {
              label: 'Off Duty',
              value: detailObj.offDuty
                ? convertToHourMinuteFormat(detailObj.offDuty)
                : '',
            },
            {
              label: 'Sleeper Berth',
              value: detailObj.sleeper
                ? convertToHourMinuteFormat(detailObj.sleeper)
                : '',
            },
            {
              label: 'Driving',
              value: detailObj.driving
                ? convertToHourMinuteFormat(detailObj.driving)
                : '',
            },
            {
              label: 'On Duty',
              value: detailObj.onDuty ? convertToHourMinuteFormat(detailObj.onDuty) : '',
            },
            {
              label: 'Personal Conveyance',
              value: detailObj.personalConveyance
                ? convertToHourMinuteFormat(detailObj.personalConveyance)
                : '',
            },
          ];

          this.violations = detailObj.violations?.length
            ? detailObj.violations.map((v: any) => ({
                label: v.standardViolationType,
                value: v.violationReason,
              }))
            : [];

          this.violations.unshift({
            label: this.newViolation.type,
            value: this.newViolation.description,
          });
        }
        this.step = 2;
        this.isLoading = false;
      },
      error: (detailErr: any) => {
        console.error('Error getHoursServiceDetail: ', detailErr);
        this.isLoading = false;
      },
    });
  }

  onViolationDateChanged(value: string) {
    const dateObj = new Date(value);
    this.hosForm.get('violationDate')?.setValue(dateObj);
    const dbDate = formatDate(dateObj);
    const found = this.allHosSummaries.find((s: any) => s.summaryDate === dbDate);

    if (!found) {
      this.hosForm.get('violationDate')?.setErrors({ noHosForThisDay: true });
    } else {
      const currentErrors = this.hosForm.get('violationDate')?.errors || {};
      delete currentErrors['noHosForThisDay'];
      if (Object.keys(currentErrors).length === 0) {
        this.hosForm.get('violationDate')?.setErrors(null);
      } else {
        this.hosForm.get('violationDate')?.setErrors(currentErrors);
      }
    }
  }
}
