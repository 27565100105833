import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from '@app/services/permission.service';
import { SidenavList } from '@app/models';
import { SidenavListConstants } from './sidenav-list.constants';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent {
  @Input() isExpanded!: boolean;
  @Output() toggleMenu: EventEmitter<boolean | null> = new EventEmitter();
  readonly pageNavItems: SidenavList[] = SidenavListConstants;

  private expandedItems: string[] = [];
  private menuItemPermissions: { [key: string]: () => boolean };
  currentPath: string = '';
  iconName!: string;
  selectedListItem: ElementRef | null = null;

  @ViewChild('floatingMenu', { static: false }) floatingMenu!: ElementRef;
  @ViewChild('menuItemText', { static: false }) menuItemText!: ElementRef;
  @ViewChild('subMenuList', { static: false }) subMenuList!: ElementRef;

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private renderer: Renderer2
  ) {
    this.menuItemPermissions = {
      Home: () => this.canViewHome(),
      Drivers: () => this.canViewDrivers(),
      Events: () => this.canViewEvents(),
      Interventions: () => this.canViewInterventions(),
      'Qualification Files': () => this.canQualificationFiles(),
      Analytics: () => this.canViewAnalytics(),
      'Driver Analytics': () => this.canViewDriverAnalytics(),
      'Event Analytics': () => this.canViewEventAnalytics(),
      'Intervention Analytics': () => this.canViewInterventionAnalytics(),
      Settings: () => this.canViewSettings(),
      'Admin Settings': () => this.canViewAdminSettings(),
      Configuration: () => this.canViewConfiguration(),
      Workflow: () => this.canViewWorkflow(),
    };
  }

  getIsActive(link: string): boolean {
    return !!link?.length && this.router.url.includes(link);
  }

  onExpandToggleClick(link: string): void {
    if (this.isMenuItemExpanded(link)) {
      this.expandedItems = this.expandedItems.filter((item: string) => item !== link);
    } else {
      this.expandedItems.push(link);
    }
  }

  isMenuItemExpanded(link: string): boolean {
    return this.expandedItems.includes(link);
  }

  shouldShowMenuItem(menuItemName: string): boolean {
    const checkFunction = this.menuItemPermissions[menuItemName];
    return checkFunction ? checkFunction() : false;
  }

  canViewHome(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Driver Profile') ||
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'My Interventions')
    );
  }

  canViewDrivers(): boolean {
    return this.permissionService.hasPermission('READ', 'Driver Profile');
  }

  canViewEvents(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Events') ||
      this.permissionService.hasPermission('WRITE', 'Events')
    );
  }

  canViewInterventions(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'My Interventions')
    );
  }

  canQualificationFiles(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Qualification Files') ||
      this.permissionService.hasPermission('WRITE', 'Qualification Files')
    );
  }

  canViewAnalytics(): boolean {
    const resources = [
      'Driver Analytics - General',
      'Driver Analytics - Scores',
      'Driver Analytics - Retention',
      'Driver Analytics - Driver List',
      'Event Analytics - General',
      'Event Analytics - Accidents',
      'Event Analytics - Injuries',
      'Event Analytics - Telematics',
      'Event Analytics - Speeding',
      'Event Analytics - HOS',
      'Event Analytics - Inspections',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('READ', resource)
    );
  }

  canViewSettings(): boolean {
    const resources = [
      'Administration - Users',
      'Administration - Roles',
      'Administration - Organization',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('WRITE', resource)
    );
  }

  canViewConfiguration(): boolean {
    const resources = [
      'Configuration - General',
      'Configuration - Event Rules',
      'Configuration - Risk Scores',
      'Configuration - Interventions',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('WRITE', resource)
    );
  }

  canViewWorkflow(): boolean {
    const resources = ['Workflow - Workflows', 'Workflow - Actions', 'Workflow - Alerts'];
    return resources.some((resource) =>
      this.permissionService.hasPermission('WRITE', resource)
    );
  }

  canViewAdminSettings(): boolean {
    const resources = [
      'Administration - Users',
      'Administration - Roles',
      'Administration - Organization',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('WRITE', resource)
    );
  }

  canViewDriverAnalytics(): boolean {
    const resources = [
      'Driver Analytics - General',
      'Driver Analytics - Scores',
      'Driver Analytics - Retention',
      'Driver Analytics - Driver List',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('READ', resource)
    );
  }

  canViewEventAnalytics(): boolean {
    const resources = [
      'Event Analytics - General',
      'Event Analytics - Accidents',
      'Event Analytics - Injuries',
      'Event Analytics - Telematics',
      'Event Analytics - Speeding',
      'Event Analytics - HOS',
      'Event Analytics - Inspections',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('READ', resource)
    );
  }

  canViewInterventionAnalytics(): boolean {
    const resources = [
      'Intervention Analytics - General',
      'Intervention Analytics - Coaching',
      'Intervention Analytics - Effectiveness',
    ];
    return resources.some((resource) =>
      this.permissionService.hasPermission('READ', resource)
    );
  }

  hideFloatingMenu(): void {
    if (!this.isExpanded) {
      const floatingMenu = this.floatingMenu.nativeElement;
      this.renderer.setStyle(floatingMenu, 'display', 'none');
    }
  }

  navigateToCurrentPath(): void {
    if (this.currentPath) {
      this.router.navigate([this.currentPath]);
    }
  }

  isSelected(route: string, isSettings: boolean = false): boolean {
    if (this.router.url === '/' + route) {
      return true;
    }
    return false;
  }

  showFloatingMenu(event: MouseEvent, menuItem: any): void {
    if (!this.isExpanded) {
      this.hideFloatingMenu();
      this.iconName = menuItem.icon;

      const floatingMenu = this.floatingMenu.nativeElement;
      const menuItemTextElement = this.menuItemText.nativeElement;
      const subMenuListElement = this.subMenuList.nativeElement;
      this.prepareMenuDisplay(
        event,
        floatingMenu,
        menuItem,
        menuItemTextElement,
        subMenuListElement
      );
      this.prepareSubMenus(subMenuListElement, menuItem);
    }
  }

  prepareMenuDisplay(
    event: MouseEvent,
    floatingMenu: any,
    menuItem: any,
    menuItemTextElement: any,
    subMenuListElement: any
  ): void {
    const rect = (event.target as Element).getBoundingClientRect();
    this.renderer.setProperty(menuItemTextElement, 'innerText', menuItem.title);
    this.renderer.setProperty(subMenuListElement, 'innerHTML', '');

    let visibleChildrenCount = 0;

    if (menuItem?.children?.length > 0) {
      menuItem.children.forEach((child: any) => {
        if (this.shouldShowMenuItem(child.permissionKey)) {
          visibleChildrenCount++;
        }
      });
    }

    if (visibleChildrenCount > 0) {
      const newHeight = (visibleChildrenCount + 1) * 47; // Calculate the new height based on number of visible children
      this.renderer.setStyle(floatingMenu, 'height', `${newHeight}px`);
    } else {
      this.renderer.removeStyle(floatingMenu, 'height');
    }

    this.renderer.setStyle(floatingMenu, 'top', `${rect.top - 70}px`);
    this.renderer.setStyle(floatingMenu, 'display', 'block');

    const menu = this.pageNavItems.find((item) => item.title === menuItem.title);
    if (menu && !menu?.children) {
      this.currentPath = menu.link;
    } else {
      this.currentPath = '';
    }
  }

  prepareSubMenus(subMenuListElement: any, menuItem: any): void {
    menuItem.children?.forEach((subMenu: any) => {
      if (this.shouldShowMenuItem(subMenu.permissionKey)) {
        const listItem = this.renderer.createElement('li');
        const isSelected = this.router.url.includes(subMenu.link);

        if (isSelected) {
          this.renderer.addClass(listItem, 'selected');
          this.selectedListItem = listItem;
        }

        this.renderer.listen(listItem, 'click', () => {
          this.selectListItem(listItem, subMenu.link, menuItem.link);
        });

        const subMenuText = this.renderer.createText(subMenu.title);
        this.renderer.appendChild(listItem, subMenuText);
        this.renderer.appendChild(subMenuListElement, listItem);
      }
    });
  }

  selectListItem(listItem: any, path: string, menuPath?: string): void {
    this.currentPath = menuPath + '/' + path;
    if (menuPath) {
      this.router.navigate([menuPath + '/' + path]);
    } else {
      this.router.navigate([path]);
    }

    if (this.selectedListItem) {
      this.renderer.removeClass(this.selectedListItem, 'selected');
    }

    this.renderer.addClass(listItem, 'selected');
    this.selectedListItem = listItem;
  }
}
