import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  private baseUrl = environment.complianceApiUrl;

  private fileRefreshSubject = new Subject<void>();
  fileRefresh$ = this.fileRefreshSubject.asObservable();

  constructor(private http: HttpClient) {}

  notifyFileRefresh() {
    this.fileRefreshSubject.next();
  }

  getOrganizationRecordTypeSettings(params: any): Observable<any> {
    const paramMappings: any = {
      // sortColumn: 'sortColumn',
      // sortDirection: 'sortDirection',
      organizationId: 'organizationId',
    };
    const url = `${this.baseUrl}/GetOrganizationRecordTypeSettings?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getOrganizationRecordTypeDetail(params: any): Observable<any> {
    const paramMappings: any = {
      recordTypeId: 'recordTypeId',
      organizationId: 'organizationId',
    };
    const url = `${this.baseUrl}/getOrganizationRecordType?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key] || request[key] === 0)
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  upsertRecordType(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/upsertOrganizationRecordType`, request);
  }

  upsertCategory(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/UpsertOrganizationCategory`, request);
  }

  deleteCategory(categoryId: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/DeleteOrganizationCategory?organizationId=${environment.organizationId}&categoryId=${categoryId}`
    );
  }

  uploadDriverFile(params: any, formData: any): Observable<any> {
    const paramMappings: any = {
      driverGuid: 'driverGuid',
      recordTypeId: 'recordTypeId',
      organizationId: 'organizationId',
      expiration: 'expiration',
      uploadedBy: 'uploadedBy',
      note: 'note',
    };
    return this.http.post(
      `${this.baseUrl}/UploadDriverFile?${this.getQueryParams(paramMappings, params)}`,
      formData
    );
  }

  getDriverFileHistory(driverGuid: string, organizationId: string): Observable<any> {
    const url = `${this.baseUrl}/GetDriverQFileHistory?driverGuid=${driverGuid}&organizationId=${organizationId}`;
    return this.http.get<any>(url);
  }

  getRecordTypeHistory(params: any): Observable<any> {
    const paramMappings: any = {
      driverGuid: 'driverGuid',
      recordTypeId: 'recordTypeId',
    };
    const url = `${this.baseUrl}/GetDriverRecordTypeHistory?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getDriverQFile(driverGuid: string, fileId: string): Observable<Blob> {
    let url = `${this.baseUrl}/GetDriverQFile?driverGuid=${driverGuid}&fileId=${fileId}`;

    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  deleteDriverFile(driverGuid: string, fileId: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/DeleteDriverFile?driverGuid=${driverGuid}&fileId=${fileId}`
    );
  }

  upsertDriverFile(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/UpsertDriverFile`, request);
  }

  updateDriverFileCurrentStatus(params: any): Observable<any> {
    const paramMappings: any = {
      driverGuid: 'driverGuid',
      fileId: 'fileId',
    };
    const url = `${this.baseUrl}/UpdateDriverFileCurrentStatus?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.post<any>(url, null);
  }
}
