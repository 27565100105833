<quill-editor
  [(ngModel)]="contents"
  (onContentChanged)="contentChanged($event)"
  [readOnly]="disabled"
  [placeholder]="placeholder"
  [ngClass]="{ 'toolbar-hidden': hideToolbar || disabled }"
>
  <div quill-editor-toolbar [style.display]="hideToolbar || disabled ? 'none' : 'block'">
    <span class="ql-formats">
      <button class="ql-bold" quill-button matTooltip="Bold" matTooltipPosition="above">
        Bold
      </button>
      <button
        class="ql-italic"
        quill-button
        matTooltip="Italic"
        matTooltipPosition="above"
      >
        Italic
      </button>
      <button
        class="ql-underline"
        quill-button
        matTooltip="Underline"
        matTooltipPosition="above"
      >
        Underline
      </button>
    </span>
    <span class="ql-formats">
      <button class="ql-link" quill-button matTooltip="Link" matTooltipPosition="above">
        Link
      </button>

      <button
        *ngIf="!hideImageUpload"
        type="button"
        class="custom-button"
        (click)="openImageSelector()"
        matTooltip="Upload Image"
        matTooltipPosition="above"
      >
        <i class="fas fa-image"></i>
      </button>
      <input
        #imageInput
        type="file"
        (change)="onImageSelected($event)"
        accept="image/*"
        multiple
        hidden
      />

      <button
        *ngIf="!hideFileUpload"
        type="button"
        class="custom-button"
        (click)="openFileSelector()"
        matTooltip="Upload File"
        matTooltipPosition="above"
      >
        <i class="fas fa-file"></i>
      </button>
      <input
        #fileInput
        type="file"
        (change)="onFileSelected($event)"
        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,.7z"
        multiple
        hidden
      />
      <!-- <button class="ql-image" quill-button>Image</button> -->
    </span>

    <!-- <span class="ql-formats">
      <button class="custom-button" (click)="handlePdfUpload()">
        <i class="fas fa-file-pdf"></i>
      </button>
      <input
        type="file"
        accept="application/pdf"
        #pdfUploadInput
        (change)="onFileInputChange($event)"
        hidden
      />
    </span> -->
    <!-- <span class="ql-formats">
      <button class="custom-button" (click)="handlePdfUpload()">
        <i class="fas fa-file-pdf"></i>
      </button>
      <input
        type="file"
        accept="application/pdf"
        #pdfUploadInput
        (change)="onFileInputChange($event)"
        hidden="true"
      />
    </span> -->
  </div>
</quill-editor>
