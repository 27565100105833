<article #scrollContainer>
  <section>
    <div class="grid">
      <div class="grid-header">
        <h2 [attr.id]="'event-details'">
          Details<i
            class="fas fa-info-circle icon-info"
            [matTooltip]="getTooltipText()"
          ></i>
        </h2>
      </div>
      <!-- spinner -->
      <div *ngIf="isLoading" class="grid-spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <div *ngIf="!isLoading" class="grid-data">
        <ng-container *ngFor="let item of eventDetails; let i = index; let last = last">
          <div class="title" [class.no-border]="last">{{ item?.title }}</div>
          <div
            class="value"
            [class.no-border]="last"
            [ngClass]="{ 'first-item': i === 0 }"
          >
            <span *ngIf="!last">{{ item?.value }}</span>
            <mat-form-field
              class="description"
              *ngIf="last"
              appearance="fill"
              style="width: 100%"
            >
              <textarea
                matInput
                placeholder=""
                [(ngModel)]="rowEvent.description"
                (input)="descriptionEdited = true"
                [disabled]="!canEditEvents()"
              >
              {{ rowEvent?.description }}
              </textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Save Description Button -->
    <div class="button-container" *ngIf="descriptionEdited">
      <button mat-flat-button color="primary" (click)="saveDescription()">Save</button>
    </div>
  </section>
  <div class="page-break"></div>
  <section>
    <div class="header-block">
      <h2 [attr.id]="'activity'">Activity</h2>
    </div>
    <!-- spinner -->
    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading">
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'accident'"
      >
        <app-activity-accident
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-accident>
      </div>
      <div class="activity-container" *ngIf="rowEvent?.type?.toLowerCase() === 'hos'">
        <app-activity-hos
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-hos>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'inspection'"
      >
        <app-activity-inspection
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-inspection>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'seatbelt'"
      >
        <app-activity-seatbelt
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
        ></app-activity-seatbelt>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'speeding'"
      >
        <app-activity-speeding
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-speeding>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'telematics'"
      >
        <app-activity-telematics
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-telematics>
      </div>
    </div>
  </section>

  <section *ngIf="canViewInterventions()">
    <div class="header-block">
      <h2 [attr.id]="'interventions'">Linked Interventions</h2>
      <div class="buttons">
        <button
          mat-button
          color="primary"
          *ngIf="canLinkEventsToInterventions()"
          (click)="linkToExisting()"
        >
          <i class="fas fa-link"></i>
          <span>Link to Existing</span>
        </button>
        <button
          mat-button
          color="primary"
          *ngIf="canLinkEventsToInterventions()"
          (click)="onAddNewClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>Add New</span>
        </button>
      </div>
    </div>
    <div class="search-block" [ngClass]="{ 'show-search': showSearch }">
      <!-- Search Intervention -->
      <mat-form-field appearance="outline" class="search-intervention intervention-input">
        <mat-chip-grid #chipList>
          <mat-chip
            *ngFor="let selectedIntervention of selectedInterventions"
            (removed)="removeIntervention(selectedIntervention)"
          >
            {{ selectedIntervention.key }}
            <span matChipRemove class="material-symbols-outlined icon-black">
              cancel
            </span>
          </mat-chip>
          <input
            type="text"
            matInput
            #interventionInput
            placeholder="Search for intervention"
            [formControl]="interventionControl"
            [matAutocomplete]="autoComplete"
            [matChipInputFor]="chipList"
          />
          <span
            *ngIf="selectedInterventions.length"
            class="icon-xmark"
            matSuffix
            (click)="resetSelectedInterventions()"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
          <span
            *ngIf="!selectedInterventions.length"
            class="icon-magnifying-glass"
            matSuffix
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </mat-chip-grid>
        <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let event of filteredInterventions | async"
            [value]="event"
            (onSelectionChange)="selectIntervention(event)"
          >
            {{ event.key }} - {{ event.driverId }} -
            {{ event.reason }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Link Buttons-->
      <div class="buttons">
        <button
          mat-button
          color="primary"
          [disabled]="!selectedInterventions.length"
          (click)="linkIntervention()"
        >
          <i class="fas fa-link"></i>
          <span>Link</span>
        </button>
        <span class="cancel-link" (click)="cancelSearch()">Cancel</span>
      </div>
    </div>

    <div
      *ngIf="!interventions.length && !isLoadingInterventions"
      class="no-linked-interventions"
    >
      No Linked Interventions
    </div>

    <!-- spinner -->
    <div *ngIf="isLoadingInterventions" class="grid-spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <!-- Intervention Table -->
    <div
      *ngIf="!isLoadingInterventions && interventions.length"
      class="interventions-table-container"
    >
      <table mat-table [dataSource]="interventions" class="interventions-table">
        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            <u>{{ intervention.ticketNum }}</u>
          </td>
        </ng-container>

        <!-- Summary Column -->
        <ng-container matColumnDef="summary">
          <th mat-header-cell *matHeaderCellDef>Summary</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            {{ intervention.reason }}
          </td>
        </ng-container>

        <!-- Event Type Column -->
        <ng-container matColumnDef="eventType">
          <th mat-header-cell *matHeaderCellDef>Event Type</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            <ng-container
              *ngFor="let eventType of convertStringToArray(intervention.eventType)"
            >
              <span class="event-type-pill">{{ eventType }}</span>
            </ng-container>
          </td>
        </ng-container>

        <!-- Priority Column -->
        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef>Priority</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            <div class="safety-indicator {{ intervention?.priority?.toLowerCase() }}">
              <i class="mark"></i>
              <i class="mark"></i>
              <i class="mark"></i>
            </div>
          </td>
        </ng-container>

        <!-- Assigned Coach Column -->
        <ng-container matColumnDef="assignedCoach">
          <th mat-header-cell *matHeaderCellDef>Assigned Coach</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            <ng-container
              *ngIf="
                intervention?.assigneeName.trim() &&
                assigneeImages[intervention.assignedCoach]
              "
            >
              <img
                [src]="assigneeImages[intervention.assignedCoach]"
                appImgFallback
                alt="Avatar"
                class="user-avatar"
              />
            </ng-container>

            <ng-container
              *ngIf="
                intervention?.assigneeName.trim() &&
                !assigneeImages[intervention.assignedCoach]
              "
            >
              <div class="name">
                <div
                  class="initials"
                  matTooltip="{{ intervention.assigneeName }}"
                  [ngClass]="getBackgroundClass(intervention.assigneeName)"
                >
                  {{ getInitials(intervention.assigneeName) }}
                </div>
                {{ intervention.assigneeName }}
              </div>
            </ng-container>

            <ng-container *ngIf="!intervention?.assigneeName.trim()">
              <img [src]="''" appImgFallback alt="Avatar" class="user-avatar" />
            </ng-container>
          </td>
        </ng-container>

        <!-- Due Date Column -->
        <ng-container matColumnDef="dueDate">
          <th mat-header-cell *matHeaderCellDef>Due Date</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            {{ intervention?.dueDate | date : "M/d/yyyy" }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td
            mat-cell
            *matCellDef="let intervention"
            [class.clickable]="canOpenInterventions(intervention)"
            (click)="
              canOpenInterventions(intervention) ? goToDetails(intervention) : null
            "
          >
            <ng-container [ngSwitch]="intervention?.status">
              <ng-container *ngFor="let status of statuses">
                <span *ngSwitchCase="status.viewValue">
                  <i [class]="status.icon"></i>
                  <span class="status-text">{{ status.viewValue }}</span>
                </span>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-col"></th>
          <td
            mat-cell
            *matCellDef="let intervention"
            class="actions-col"
            (click)="$event.stopPropagation()"
          >
            <button
              mat-icon-button
              [matMenuTriggerFor]="interventionMenu"
              aria-label="Menu button"
            >
              <i class="fas fa-ellipsis"></i>
            </button>
            <mat-menu #interventionMenu="matMenu">
              <button mat-menu-item (click)="onUnlinkIntervention($event, intervention)">
                Unlink
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let intervention; columns: displayedColumns"></tr>
      </table>
    </div>
  </section>

  <!-- Notes Section -->
  <section [attr.id]="'notes-events'">
    <app-notes
      [avatarUrl]="avatarUrl"
      [userName]="userName"
      [contents]="contents"
      [notes]="notes"
      [entityId]="rowData.id"
      [sectionId]="'notes'"
      [entityType]="entityType"
    ></app-notes>
  </section>
</article>

<ul *ngIf="sections.length > 1 && showMenu">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
