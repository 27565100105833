import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { OrganizationService } from '@app/services/organization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() toggleMobileMenu: EventEmitter<boolean | null> = new EventEmitter();
  isMenuOpened = false;
  loginDisplay = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router, private organizationService: OrganizationService) {
    router.events.subscribe(() => {
      this.isMenuOpened = false;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onMenuClick(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.toggleMobileMenu.emit(null);
  }
}
