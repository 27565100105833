import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

import { DialogNewHosComponent } from './dialog-new-hos.component';
import { DateTimePickerModule } from '@app/shared/components/date-time-picker/date-time-picker.module';
import { DatePickerModule } from '@app/shared/components/date-picker/date-picker.module';

@NgModule({
  declarations: [DialogNewHosComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    DateTimePickerModule,
    MatProgressSpinnerModule,
    MatTableModule,
    DatePickerModule,
  ],
  exports: [DialogNewHosComponent],
})
export class DialogNewHosModule {}
