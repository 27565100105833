<mat-toolbar class="custom-toolbar">
  <div class="branding">
    <div routerLink="/home" class="logo"></div>
    <a routerLink="/home">Driver Safety</a>
  </div>

  <div class="actions">
    <app-sub-header (menuItemClicked)="onMenuClick()"></app-sub-header>
    <div class="mobile">
      <button mat-fab color="primary" (click)="onMenuClick()" class="inverse mat-fab">
        <i class="fas fa-{{ isMenuOpened ? 'xmark' : 'bars' }}"></i>
      </button>
    </div>
  </div>
</mat-toolbar>
