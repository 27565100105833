<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>{{ data?.title }}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div [innerHTML]="data?.text"></div>

  <div class="buttons">
    <ng-container *ngIf="!data?.confirm; else confirmButtonTemplate">
      <button mat-flat-button color="primary" [mat-dialog-close]="true">
        {{ data?.yesButtonText ? data?.yesButtonText : "Yes" }}
      </button>
      <button
        mat-stroked-button
        color="{{ data?.noWarn ? 'basic' : 'warn' }}"
        [mat-dialog-close]="false"
      >
        {{ data?.noButtonText ? data?.noButtonText : "No" }}
      </button>
    </ng-container>
    <ng-template #confirmButtonTemplate>
      <button mat-flat-button color="primary" [mat-dialog-close]="true">Okay</button>
    </ng-template>
  </div>
</mat-dialog-content>
