import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillEditorComponent } from 'ngx-quill';
import { FormsModule } from '@angular/forms';

// Material
import { MatTooltipModule } from '@angular/material/tooltip';

import { EditorComponent } from './editor.component';

@NgModule({
  declarations: [EditorComponent],
  exports: [EditorComponent],
  imports: [FormsModule, CommonModule, QuillEditorComponent, MatTooltipModule],
})
export class EditorModule {}
