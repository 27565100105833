import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { DialogDriverService } from '@app/services/dialog-driver.service';
import { FilesViewerModalComponent } from '@app/shared/components/files-viewer-modal/files-viewer-modal.component';
import { NewFileModalComponent } from '../new-file-modal/new-file-modal.component';
import { ToasterService } from '@app/services/toaster.service';
import { ModalMode } from '@app/models/general.model';
import { ExpandViewService } from '@app/services/expand-view.service';
import { ComplianceService } from '@app/services/compliance.service';
import { convertDateFormat, extractDate } from '@app/shared/helpers/date.helper';
import { ComplianceStatus } from '@app/models/compliance.model';

interface Record {
  view: string;
  recordType: string;
  uploaded: string;
  expires: string;
  complianceSchema: string;
  status: string;
}

interface Section {
  title: string;
  id: string;
  records: Record[];
  isViewMore: boolean;
}

@Component({
  selector: 'app-tab-sections-files',
  templateUrl: './tab-sections-files.component.html',
  styleUrls: ['../tab-sections.component.scss'],
})
export class TabSectionsFilesComponent implements OnInit, OnDestroy {
  @Input() driverGuid!: string;

  isLoading: boolean = false;
  maxDisplayedRecords: number = 6;
  dataSources: any = [];

  urls = ['https://ontheline.trincoll.edu/images/bookdown/sample-local-pdf.pdf'];

  displayedColumns = [
    'view',
    'recordType',
    'uploaded',
    'expires',
    'complianceSchema',
    'status',
  ];
  onlyCompliance: boolean = false;

  sections: Section[] = [];
  sectionSelected: string = '';
  selectedOrganization: any = {};

  subscription!: Subscription;

  convertDateFormat = convertDateFormat;
  extractDate = extractDate;
  complianceStatus = ComplianceStatus;

  @ViewChildren(MatSort) sorts!: QueryList<MatSort>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialogInfoService: DialogDriverService,
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private expandViewService: ExpandViewService,
    private complianceService: ComplianceService
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.loadSections();
    setTimeout(() => this.dialogInfoService.viewInit(255));

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.loadMore();
    });

    this.complianceService.fileRefresh$.subscribe(() => {
      this.loadSections();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  loadMore(): void {
    this.sections.forEach((section) => {
      if (section.isViewMore) {
        this.toggleViewMore(section);
      }
      this.expandViewService.incrementExpandView();
    });
  }

  loadSections(): void {
    const organizationId = this.selectedOrganization?.organizationId;

    this.isLoading = true;
    this.complianceService
      .getDriverFileHistory(this.driverGuid, organizationId)
      .subscribe({
        next: (response) => {
          this.sections = Object.keys(response).map((key) => {
            const filteredRecords = response[key].filter((record: any) => {
              return !(record.archive === true && !record.id);
            });

            return {
              title: key,
              id: key.toLowerCase().replace(/\s+/g, '-'),
              records: filteredRecords.map((record: any) => ({
                ...record,
                view: '',
                recordType: record.recordTypeName,
                uploaded: record.uploaded,
                expires: record.expires || null,
                complianceSchema: record.includeInSchema ? 'Included' : 'Not Included',
                status: record.complianceStatus,
              })),
              isViewMore: true,
            };
          });

          this.setInitialDataSources();
          this.isLoading = false;
        },
        error: (err) => {
          console.error('Error loading sections:', err);
          this.isLoading = false;
        },
      });
  }

  private setInitialDataSources(): void {
    this.sections.forEach((section) => {
      this.dataSources[section.id] = new MatTableDataSource(
        this.onlyCompliance
          ? section.records.filter((record) => record.complianceSchema === 'Included')
          : section.records.slice(0, this.maxDisplayedRecords)
      );
    });
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
    this.sections.forEach((section, index) => {
      const sort = this.sorts.toArray()[index];
      if (this.dataSources[section.id]) {
        this.dataSources[section.id].sort = sort;
      }
    });
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  onNavClick(elementId: string): void {
    this.sectionSelected = elementId;
    this.dialogInfoService.onNavClick(elementId);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case ComplianceStatus.COMPLIANT:
        return 'status-compliant';
      case ComplianceStatus.EXPIRED:
        return 'status-expired';
      case ComplianceStatus.MISSING:
        return 'status-missing';
      case ComplianceStatus.EXPIRES_SOON:
        return 'status-expires-soon';
      case ComplianceStatus.PROCESSING:
        return 'status-processing';
      case ComplianceStatus.INCORRECT:
        return 'status-incorrect';
      case ComplianceStatus.NEEDS_REVIEW:
        return 'status-needs-review';
      case ComplianceStatus.PROVIDED:
        return 'status-provided';
      default:
        return '';
    }
  }

  getStatusIcon(status: string): string {
    switch (status) {
      case ComplianceStatus.COMPLIANT:
        return 'fas fa-check-circle';
      case ComplianceStatus.EXPIRED:
        return 'fas fa-times-circle';
      case ComplianceStatus.MISSING:
        return 'fas fa-times-circle';
      case ComplianceStatus.EXPIRES_SOON:
        return 'fas fa-check-circle';
      case ComplianceStatus.PROVIDED:
        return 'fas fa-check-circle';
      case ComplianceStatus.PROCESSING:
        return 'fas fa-clock';
      case ComplianceStatus.INCORRECT:
        return 'fas fa-times-circle';
      case ComplianceStatus.NEEDS_REVIEW:
        return 'fas fa-times-circle';
      default:
        return '';
    }
  }

  toggleViewMore(section: Section): void {
    section.isViewMore = !section.isViewMore;

    let filteredRecords = section.records;

    if (this.onlyCompliance) {
      filteredRecords = section.records.filter(
        (record) => record.complianceSchema === 'Included'
      );
    }

    this.dataSources[section.id].data = section.isViewMore
      ? filteredRecords.slice(0, this.maxDisplayedRecords)
      : filteredRecords;
  }

  onIconClick(record: any) {
    const sanitizedRecordTypeName = record.recordTypeName.replace(/\s+/g, '_');
    const fileExtension = record.fileExtension || 'pdf';
    const fileName = `${sanitizedRecordTypeName}.${fileExtension}`;

    this.complianceService.getDriverQFile(this.driverGuid, record.id).subscribe({
      next: (response) => {
        const blob = new Blob([response], { type: `application/${fileExtension}` });
        const file = new File([blob], fileName, { type: `application/${fileExtension}` });
        const blobUrl = window.URL.createObjectURL(file);
        const urlWithFilename = `${blobUrl}#filename=${fileName}`;
        this.urls = [urlWithFilename];
        this.openFilesViewerModal();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  openFilesViewerModal() {
    const dialogRef = this.dialog.open(FilesViewerModalComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      data: { urls: this.urls, currentIndex: 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onRowClick(record: any): void {
    const hideUploadNewFile =
      record.archive === true && record.status !== this.complianceStatus.MISSING;

    const dialogRef = this.dialog.open(NewFileModalComponent, {
      width: '660px',
      panelClass: 'single',
      data: {
        ...record,
        driverGuid: this.driverGuid,
        mode: ModalMode.EDIT,
        hideUploadNewFile,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toasterService.showSuccess('The File was updated successfully');
        this.loadSections();
      }
    });
  }

  toggleOnlyCompliance(event: any) {
    this.onlyCompliance = event.checked;
    this.updateDataSources();
  }

  updateDataSources(): void {
    this.sections.forEach((section) => {
      const filteredRecords = this.onlyCompliance
        ? section.records.filter((record) => record.complianceSchema === 'Included')
        : section.records;

      this.dataSources[section.id].data = filteredRecords.slice(
        0,
        section.isViewMore ? this.maxDisplayedRecords : filteredRecords.length
      );
    });
  }

  getExpiresValue(record: any): string {
    // If record.expires is truthy, return its formatted date
    if (record.expires) {
      return this.convertDateFormat(record.expires);
    }

    // If record.expires doesn't exist, decide according to record.expiration and complianceStatus
    if (record.expiration === false) {
      return 'n/a';
    }

    // If record.expiration is true and complianceStatus is MISSING, show "-"
    if (
      record.expiration === true &&
      record.complianceStatus === this.complianceStatus.MISSING
    ) {
      return '-';
    }

    // Default fallback, in case you want a generic symbol (optional)
    return '-';
  }
}
