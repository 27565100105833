<mat-dialog-actions>
  <div class="dialog-close">
    <i class="fas fa-times" (click)="onDialogCancelClick()"></i>
  </div>
  <div mat-dialog-title>
    <span *ngIf="step === 1">Add HOS Violation</span>
    <span *ngIf="step === 2">Review HOS Violation</span>
  </div>
</mat-dialog-actions>

<mat-dialog-content>
  <!-- Step 1 -->
  <div *ngIf="step === 1">
    <div class="text">
      <span>
        Please select the Hours of Service Violation, the date it occurred, and provide a
        description of the violation.
      </span>

      <!-- Already exists error -->
      <div class="alert-container" *ngIf="showExistsModal">
        <div class="alert-bar"></div>
        <div class="alert-content">
          <h4>
            <i class="fas fa-triangle-exclamation exclamation-icon"></i>
            <span>HOS Violation Already Exists</span>
          </h4>
          <p>
            An HOS Violation cannot be added because the violation already exists. Select
            another day or violation.
          </p>
        </div>
      </div>

      <!-- No HOS for this day error -->
      <div
        class="alert-container"
        *ngIf="hosForm.get('violationDate')?.hasError('noHosForThisDay')"
      >
        <div class="alert-bar"></div>
        <div class="alert-content">
          <h4>
            <i class="fas fa-triangle-exclamation exclamation-icon"></i>
            <span>No Time Logged on Selected Date</span>
          </h4>
          <p>
            An HOS Violation cannot be added because the driver has not logged any time
            for the selected date. Please select another day.
          </p>
        </div>
      </div>
    </div>
    <form [formGroup]="hosForm">
      <!-- HOS Violation -->
      <mat-form-field appearance="outline" class="mid-width">
        <mat-label>HOS Violation</mat-label>
        <mat-select formControlName="hosViolation">
          <mat-option *ngFor="let option of violationOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Violation Date -->
      <div class="date-picker-container" class="violation-date-container">
        <div class="small-width">
          <app-date-picker
            [label]="'Violation Date'"
            [required]="true"
            [disabled]="false"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [value]="hosForm.get('violationDate')?.value"
            (dateChanged)="onViolationDateChanged($event)"
          ></app-date-picker>
        </div>
      </div>

      <!-- Description -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput rows="4" formControlName="description"></textarea>
      </mat-form-field>
    </form>
  </div>

  <!-- Step 2 -->
  <div *ngIf="step === 2">
    <div class="text">
      <span> Confirm the details of the violation. </span>
    </div>

    <div class="content">
      <div class="row">
        <div class="row-item">
          <div class="title">Activity Details</div>
          <hr />
          <table mat-table [dataSource]="activityDetails" class="hos-table">
            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
          <hr />
        </div>

        <div class="row-item">
          <div class="title">HOS Summary</div>
          <hr />
          <div *ngIf="isLoading" class="spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="30"
              class="spinner"
            ></mat-progress-spinner>
          </div>
          <table *ngIf="!isLoading" mat-table [dataSource]="hosSummary" class="hos-table">
            <ng-container matColumnDef="label">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
          <hr />
        </div>
      </div>

      <div class="full-width-row">
        <div class="violation-title">Violations</div>
        <div *ngIf="isLoading" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading && violations.length === 0" class="no-records-found">
          <p>No violations found.</p>
        </div>
        <table
          *ngIf="!isLoading && violations.length"
          mat-table
          [dataSource]="violations"
          class="hos-table"
        >
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <!-- “Next” button only visible when step === 1 -->
  <button
    mat-flat-button
    color="primary"
    (click)="onNextClick()"
    [disabled]="step !== 1 || hosForm.invalid || isLoading"
    *ngIf="step === 1"
  >
    <span>Next</span>
    <span *ngIf="isLoading" class="button-spinner">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="18"
        strokeWidth="2"
        class="spinner"
      >
      </mat-progress-spinner>
    </span>
  </button>

  <!-- Cancel Button -->
  <button mat-button (click)="onDialogCancelClick()" *ngIf="step === 1">
    <i class="fas fa-ban"></i> Cancel
  </button>

  <!-- “Add Violation” button only visible when step === 2 -->
  <button
    mat-flat-button
    color="primary"
    (click)="confirmAddViolation()"
    *ngIf="step === 2"
  >
    <i class="fas fa-plus-circle"></i>
    Add Violation
  </button>

  <!-- Back to New HOS Violation Button -->
  <button mat-button (click)="onDialogBackClick()" *ngIf="step === 2">
    <i class="fas fa-arrow-left"></i> Back to New HOS Violation
  </button>
</mat-dialog-actions>
