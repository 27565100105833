import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';
import { OrganizationService } from '@app/services/organization.service';
import { UserManagementService } from '@app/services/user-management.service';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-role-search-multiple',
  templateUrl: './role-search-multiple.component.html',
  styleUrls: ['./role-search-multiple.component.scss'],
})
export class RoleSearchMultipleComponent implements OnInit, AfterViewInit {
  @Input() label: string = '';
  @Input() placeholder: string = 'Search...';
  @Input() roles!: any;
  @Output() roleIdSelected = new EventEmitter<any>();

  selectedRoles: any[] = [];
  roleControl = new FormControl();
  filteredRoles!: Observable<any[]>;
  isLoading: boolean = false;
  allRoles: any[] = [];

  selectedOrganization: any = {};

  @ViewChild('userInput')
  userInput!: ElementRef<HTMLInputElement>;

  constructor(private userManagementService: UserManagementService) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.getRoleList();
    this.setupRoleAutocomplete();
    this.setRoles();
  }

  ngAfterViewInit(): void {
    this.roleControl.markAsDirty();
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  getRoleList(): void {
    const organizationId = this.selectedOrganization.organizationId;
    this.userManagementService.getGroups(organizationId).subscribe({
      next: (res) => {
        if (res.groups) {
          const sortedRoles = res.groups.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          this.allRoles = sortedRoles;
          this.roleControl.setValue('');
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setRoles(): void {
    if (this.roles?.length) {
      this.roles.forEach((roleItem: any) => {
        if (!this.selectedRoles.some((role) => role.groupId === roleItem.groupId)) {
          this.selectedRoles.push(roleItem);
        }
      });
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input) {
      this.roleControl.setValue(input.value, { emitEvent: true });
    }
  }

  onBlur(): void {
    this.roleControl.setValue('');
    this.userInput.nativeElement.value = '';
  }

  setupRoleAutocomplete(): void {
    this.filteredRoles = this.roleControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => this.searchRoles(value))
    );
  }

  searchRoles(searchTerm: string): Observable<any> {
    this.isLoading = true;

    if (!searchTerm) {
      this.isLoading = false;
      return of(this.allRoles);
    }

    const filtered = this.allRoles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    this.isLoading = false;
    return of(filtered);
  }

  removeRole(role: any): void {
    const index = this.selectedRoles.indexOf(role);
    if (index >= 0) {
      this.selectedRoles.splice(index, 1);
      this.userInput.nativeElement.value = '';
      this.roleControl.setValue('');
      this.roleIdSelected.emit(this.selectedRoles);
    }
  }

  resetSelectedRoles(): void {
    this.userInput.nativeElement.value = '';
    this.selectedRoles = [];
    this.roleControl.setValue('');
    this.roleIdSelected.emit(null);
  }

  displayFn(role: any): string {
    return role ? role.name : '';
  }

  selectRole(role: any): void {
    if (!this.selectedRoles.some((e) => e.groupId === role.groupId)) {
      this.selectedRoles.push(role);
    }
    this.roleIdSelected.emit(this.selectedRoles);
  }
}
