export enum ComplianceStatus {
  NEEDS_REVIEW = 'Needs Review',
  INCORRECT = 'Incorrect',
  EXPIRES_SOON = 'Expires Soon',
  MISSING_FILE = 'Missing File',
  EXPIRED = 'Expired',
  COMPLIANT = 'Compliant',
  MISSING = 'Missing',
  PROCESSING = 'Processing',
  PROVIDED = 'Provided',
}
