<mat-form-field
  appearance="outline"
  class="custom-input"
  [ngClass]="{ 'has-selected-roles': selectedRoles.length > 0 }"
>
  <mat-chip-grid #chipList>
    <mat-chip
      *ngFor="let selectedRole of selectedRoles"
      (removed)="removeRole(selectedRole)"
    >
      <span class="inline-container">
        {{ selectedRole.name }}
      </span>
      <span matChipRemove class="material-symbols-outlined icon-black"> cancel </span>
    </mat-chip>

    <input
      type="text"
      matInput
      #userInput
      [placeholder]="placeholder"
      [matAutocomplete]="autoComplete"
      [matChipInputFor]="chipList"
      (input)="onInput($event)"
      (blur)="onBlur()"
    />
    <span
      *ngIf="selectedRoles.length"
      class="icon-xmark"
      matSuffix
      (click)="resetSelectedRoles()"
    >
      <i class="fa-solid fa-xmark"></i>
    </span>
    <span *ngIf="!selectedRoles.length" class="icon-magnifying-glass" matSuffix>
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
  </mat-chip-grid>
  <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn">
    <!-- Spinner -->
    <mat-option *ngIf="isLoading">
      <div class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="20"
          class="spinner"
        ></mat-progress-spinner>
      </div>
    </mat-option>
    <!-- Roles options-->
    <mat-option
      *ngFor="let role of filteredRoles | async"
      [value]="role"
      (onSelectionChange)="selectRole(role)"
    >
      <div class="role-option-content">
        <span>{{ role.name }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
