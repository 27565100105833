<!-- DESKTOP LAYOUT -->
<ng-container *ngIf="!isMobile">
  <section>
    <div class="block left">
      <!-- Organization -->
      <mat-form-field
        appearance="outline"
        class="organization-dropdown"
        *ngIf="organizations.length > 1"
      >
        <span matPrefix>
          <i class="fa-solid fa-warehouse"></i>
        </span>
        <mat-select
          [(value)]="selectedOrganization"
          (selectionChange)="onOrganizationChange($event)"
        >
          <mat-option *ngFor="let organization of organizations" [value]="organization">
            {{ organization.organizationName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="block right">
      <button
        *ngIf="canCreateAccidents()"
        mat-flat-button
        color="primary"
        class="accident"
        (click)="onNewAccidentClick()"
      >
        <i class="fas fa-plus-circle"></i>
        <span>New Accident</span>
      </button>
      <button
        *ngIf="canCreateInjury()"
        mat-flat-button
        color="primary"
        class="injury"
        (click)="onNewInjuryClick()"
      >
        <i class="fas fa-plus-circle"></i>
        <span>New Injury</span>
      </button>
    </div>

    <div class="block">
      <div class="displaySettings">
        <a (click)="onMenuItemClick('settings')" class="settings">
          <div class="display-settings">
            <i class="fas fa-desktop"></i>
            <span class="display-link">Display Settings</span>
          </div>
        </a>
      </div>
      <!-- Profile -->
      <div class="profile-menu">
        <a class="profile" (click)="onProfileMenuToggle()">
          <img
            class="circle-image"
            *ngIf="photoUrl"
            [src]="photoUrl"
            alt="User Image"
            matTooltip="{{ name }}"
          />
          <div
            *ngIf="!photoUrl"
            class="initials"
            matTooltip="{{ name }}"
            [ngClass]="getBackgroundClass(name)"
          >
            {{ getInitials(name) }}
          </div>
        </a>
        <div *ngIf="isDropdownMenuOpened" class="dropdown-menu">
          <ul>
            <li>
              <a (click)="openAccountProfileModal()">Profile</a>
            </li>
            <li><a (click)="logout()">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- MOBILE LAYOUT -->
<ng-container *ngIf="isMobile">
  <section class="mobile-layout">
    <!-- First row: Organization and Avatar -->
    <div class="mobile-block top-row">
      <mat-form-field
        appearance="outline"
        class="organization-dropdown"
        *ngIf="organizations.length > 1"
      >
        <span matPrefix>
          <i class="fa-solid fa-warehouse"></i>
        </span>
        <mat-select
          [(value)]="selectedOrganization"
          (selectionChange)="onOrganizationChange($event)"
        >
          <mat-option *ngFor="let organization of organizations" [value]="organization">
            {{ organization.organizationName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="profile-menu">
        <a class="profile" (click)="onProfileMenuToggle()">
          <img
            class="circle-image"
            *ngIf="photoUrl"
            [src]="photoUrl"
            alt="User Image"
            matTooltip="{{ name }}"
          />
          <div
            *ngIf="!photoUrl"
            class="initials"
            matTooltip="{{ name }}"
            [ngClass]="getBackgroundClass(name)"
          >
            {{ getInitials(name) }}
          </div>
        </a>
        <div *ngIf="isDropdownMenuOpened" class="dropdown-menu">
          <ul>
            <li>
              <a (click)="openAccountProfileModal()">Profile</a>
            </li>
            <li><a (click)="logout()">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Second row: New Accident and Display Settings -->
    <div class="mobile-block middle-row">
      <button
        *ngIf="canCreateAccidents()"
        mat-flat-button
        color="primary"
        class="accident"
        (click)="onNewAccidentClick()"
      >
        <i class="fas fa-plus-circle"></i>
        <span>New Accident</span>
      </button>
      <div class="display-settings">
        <a (click)="onMenuItemClick('settings')" class="settings">
          <div class="display-settings">
            <span class="display-link">Display Settings</span>
            <i class="fas fa-desktop"></i>
          </div>
        </a>
      </div>
    </div>

    <!-- Third row: New Injury -->
    <div class="mobile-block bottom-row">
      <button
        *ngIf="canCreateInjury()"
        mat-flat-button
        color="primary"
        class="injury"
        (click)="onNewInjuryClick()"
      >
        <i class="fas fa-plus-circle"></i>
        <span>New Injury</span>
      </button>
    </div>
  </section>
</ng-container>
